<template>
    <div class="test-container">
        <el-divider content-position="left">控制台</el-divider>
        <em>[Here is Import Some Module]</em>
    </div>
</template>
<script>
    export default {
        name: 'home',
        data() {
            return {
                show: true,
                token: this.$store.getters['user/token']
            }
        },
        created() { },
        mounted() { },
        methods: {},
    }
</script>

